import activity from './m-kickoff.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Kickoff',
  icon: 'mdi-football-australian',
  description: 'Kicking off the event with instructions',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#6eba80'
  },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-kickoff',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        channels: [],
        videoaskEmbed: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
